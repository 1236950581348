import React from 'react';
import { ResponsivePNG } from 'sports-react';
import logEvent from '~/util/analytics';
import StyledComponent from '~/components/Styled/Styled';
import classnames from 'classnames';
import Link from '~/components/Link/Link';

import css from '~/components/Footer/Footer.scss';

const handleGetTheAppClick = link => {
  logEvent('conversion', { type: link, campaign: 'thescoreapp' });
};

const Footer = () => (
  <div className={css.footerContainer}>
    <div className={css.footerInnerContainer}>
      <div className={classnames(css.footerLeft)}>
        <Link route="/">
          <a aria-label="theScore site" className={css.logoWrapper}>
            <img src="/static/vectors/the-score-logo.svg" alt="theScore" />
          </a>
        </Link>
        <div className={css.captionText}>The ultimate, personalized mobile sports experience</div>
        <div className={css.badgesWrapper}>
          <div className={css.phoneBox}>
            <a
              className={css.appStoreButton}
              aria-label="Download from app store"
              href="https://thescore.app.link/uKPvChyCYG"
              onClick={() => {
                handleGetTheAppClick('iOSFooterDownload');
              }}
            >
              <ResponsivePNG
                src="/static/images/app-store-footer.png"
                alt="App Store"
                height="36px"
                width="107px"
              />
            </a>
            <a
              className={css.appStoreButton}
              aria-label="Download from google play"
              href="https://thescore.app.link/KDCcpaGCYG"
              onClick={() => {
                handleGetTheAppClick('AndroidFooterDownload');
              }}
            >
              <ResponsivePNG
                src="/static/images/google-play.png"
                alt="Google Play"
                height="36px"
                width="121px"
              />
            </a>
          </div>
        </div>
      </div>
      <div className={css.footerRight}>
        <ul className={classnames(css.footerList, css.section, 'col-lg-4')}>
          <li className={css.footerListTitle}>
            <h2>Top Leagues</h2>
          </li>
          <li className={css.footerListItem}>
            <a href="/nba/news" className={css.footerLink}>
              NBA Basketball
            </a>
          </li>
          <li className={css.footerListItem}>
            <a href="/nfl/news" className={css.footerLink}>
              NFL Football
            </a>
          </li>
          <li className={css.footerListItem}>
            <a href="/nhl/news" className={css.footerLink}>
              NHL Hockey
            </a>
          </li>
          <li className={css.footerListItem}>
            <a href="/mlb/news" className={css.footerLink}>
              MLB Baseball
            </a>
          </li>
          <li className={css.footerListItem}>
            <a href="/ncaab/news" className={css.footerLink}>
              NCAA Mens Basketball
            </a>
          </li>
        </ul>
        <ul className={classnames(css.footerList, css.section, 'col-lg-3')}>
          <li className={css.footerListTitle}>
            <h2>Company</h2>
          </li>
          <li className={css.footerListItem}>
            <a href="https://www.scoremediaandgaming.com/about/" className={css.footerLink}>
              About Us
            </a>
          </li>
          <li className={css.footerListItem}>
            <a href="https://thescoresupport.zendesk.com/hc/en-us/" className={css.footerLink}>
              Support
            </a>
          </li>
          <li className={css.footerListItem}>
            <a href="https://news.scoremediaandgaming.com/" className={css.footerLink}>
              News
            </a>
          </li>
          <li className={css.footerListItem}>
            <a href="https://www.scoremediaandgaming.com/careers/" className={css.footerLink}>
              Careers
            </a>
          </li>
        </ul>
        <ul className={classnames(css.footerList, css.section, 'col-lg-3')}>
          <li className={css.footerListTitle}>
            <h2>Follow Us</h2>
          </li>
          <li className={css.footerListItem}>
            <a href="https://twitter.com/theScore" className={css.footerLink}>
              Twitter
            </a>
          </li>
          <li className={css.footerListItem}>
            <a href="https://www.facebook.com/theScore" className={css.footerLink}>
              Facebook
            </a>
          </li>
          <li className={css.footerListItem}>
            <a href="https://instagram.com/thescore" className={css.footerLink}>
              Instagram
            </a>
          </li>
          <li className={css.footerListItem}>
            <a href="https://www.tiktok.com/@thescore" className={css.footerLink}>
              TikTok
            </a>
          </li>
        </ul>
        <ul className={classnames(css.footerList, css.section, 'col-lg-3')}>
          <li className={css.footerListTitle}>
            <h2>Products</h2>
          </li>
          <li className={css.footerListItem}>
            <a href="https://thescore.com" className={css.footerLink}>
              <img src="/static/vectors/the-score-footer-logo.svg" alt="theScore" />
            </a>
          </li>
          <li className={css.footerListItem}>
            <a href="https://thescore.bet" className={css.footerLink}>
              <img src="/static/vectors/thescore-bet-footer-logo.svg" alt="theScore Bet" />
            </a>
          </li>
          <li className={css.footerListItem}>
            <a href="https://thescoreesports.com/" className={css.footerLink}>
              <img src="/static/vectors/e-sports-footer-logo.svg" alt="theScore eSports" />
            </a>
          </li>
        </ul>
      </div>
    </div>

    <div className={css.copySection}>
      <a
        className={classnames(css.mobileOnly, css.pennLogoMobile, 'margin-botton-10')}
        aria-label="Visit Penn Entertainment's website"
        href="https://www.pennentertainment.com/"
      >
        <img src="/static/vectors/penn-gaming-logo-v2.svg" alt="Penn Entertainment's logo" />
      </a>

      <div>
        <div className={classnames(css.footerCopyright, 'col-xs-12')}>
          <a
            href="https://thescoresupport.zendesk.com/hc/en-us/sections/13873291774733-Copyright-Notices"
            className={css.footerLink}
          >
            <span className={css.footerCopyrightText}>
              <span>
                Copyright &copy; {new Date().getFullYear()} theScore. All Rights Reserved. Certain
                content reproduced under license.
              </span>
            </span>
          </a>
        </div>
        <ul className={classnames(css.footerLegal, 'col-xs-12')}>
          <li className={css.footerLegalListItem}>
            <a
              href="https://thescoresupport.zendesk.com/hc/en-us/articles/13873304224397-Privacy-Policy"
              className={css.footerLink}
            >
              Privacy Policy
            </a>
          </li>
          <li className={css.footerLegalListItem}>
            <a
              href="https://thescoresupport.zendesk.com/hc/en-us/articles/13873292631949-Terms-of-Use"
              className={css.footerLink}
            >
              Terms of Use
            </a>
          </li>
          <li className={css.footerLegalListItem}>
            <a
              href="https://thescoresupport.zendesk.com/hc/en-us/articles/13873305361165-Statement-of-Commitment-to-Accessibility"
              className={css.footerLink}
            >
              Accessibility Policies
            </a>
          </li>
          <li className={css.footerLegalListItem}>
            <a href="https://www.essentialaccessibility.com/thescore?utm_source=thescorehomepage&utm_medium=iconlarge&utm_term=eachannelpage&utm_content=header&utm_campaign=thescore">
              <img
                className={css.eaIcon}
                src="/static/vectors/eA-Icon.svg"
                alt="This icon serves as a link to download the eSSENTIAL Accessibility assistive technology app for individuals with physical disabilities. It is featured as part of our commitment to diversity and inclusion."
              />
            </a>
          </li>
        </ul>
      </div>
      <div>
        <a
          className={classnames(css.hideMobile, css.pennLogo)}
          aria-label="Visit Penn Entertainment's website"
          href="https://www.pennentertainment.com/"
          title="Penn Entertainment"
        >
          <img src="/static/vectors/penn-gaming-logo-v2.svg" alt="Penn Entertainment's logo" />
        </a>
      </div>
    </div>
  </div>
);

Footer.displayName = 'Footer';

export default StyledComponent(Footer, [css]);
